import React, { useState, useEffect } from "react";
import "../styles/login.css";
import indonesia from "../assets/images/indonesia.png";
import PurpleImg from "../assets/images/purple-logo.jpg";
import { Button, Input, Spin, Dropdown, Menu } from "antd";
import {
  authenticate,
  digipacIndoLogin,
  getTokenUserSegmentation,
} from "../api/requests";
import { Navigate, useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "#FF1E1E" }} spin />
);

const DigiPacIndoLogin = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [redirectTo, setRedirectTo] = useState(false);
  const [currentCountry, setCurrentCountry] = useState({
    countryName: "indonesia",
    code: "IND",
    countryCode: "+62",
  });

  let numberLength = 13;

  const menu_flag = (
    <Menu>
      <Menu.Item
        onClick={() =>
          setCurrentCountry({
            countryName: "Indonesia",
            code: "IND",
            countryCode: "+62",
          })
        }
      >
        <div style={styles.flag_dropdown}>
          <img
            src={indonesia}
            className="flagstyle"
            style={{
              // border: "2px solid",
              borderRadius: 3,
            }}
          />
          <p style={styles.flag_text}>Indonesia (+62)</p>
        </div>
      </Menu.Item>
    </Menu>
  );

  const LoginFunction = () => {
    setError("");
    console.log("Login");
    if (number == "") {
      setError("Field is empty. Enter a valid number.");
    } else if (
      // number.substr(0, 1) == "3" &&
      number.length <= numberLength &&
      number.match(/^[0-9]+$/)
    ) {
      setLoading(true);
      setError("");

      if (currentCountry.code === "IND") {
        let phoneNumber = "62" + number;
        // generateRecaptcha()
        let payload = {
          msisdn: phoneNumber,
        };
        digipacIndoLogin(payload).then((response) => {
          console.log("get token no no no is :::", response);
          if (response.status === 200) {
            localStorage.clear();
            // localStorage.setItem("operator", 9);
            // localStorage.setItem("mza", true);
            setLoading(false);
            authenticate(response.user.token, phoneNumber, "IND", () => {
              setRedirectTo(true);
            });
          } else {
            setError(response.message);
            setLoading(false);
          }
        });
      }
    } else {
      setError("Invalid entry.");
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      console.log("do validate");
      LoginFunction();
    }
  };
  //   const handleOtpKeyDown = (e) => {
  //     if (e.key === "Enter") {
  //       console.log("otp enter");
  //       onSubmit();
  //     }
  //   };

  if (redirectTo) {
    return <Navigate to="/Game" state={location.state} />;
  }

  return (
    <div className="Login-page-container">
      <div className="containerLeft"></div>
      <div
        className="containerRight"
        style={{ padding: 0, justifyContent: "flex-start" }}
      >
        <div className="logo-desktop">
          <div
            className="purpleImg"
            style={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              backdropFilter: "blur(20px)",
            }}
          ></div>
          <img
            className="purpleImg"
            src={PurpleImg}
            style={{
              zIndex: 1,
              position: "relative",
              height: "100%",
            }}
          />
        </div>

        <div
          className="digipaclogin"
          style={{ paddingLeft: "6vw", paddingRight: "6vw", marginTop: "10px" }}
        >
          <div style={{ flexDirection: "row" }}>
            <span className="titletext" style={{ color: "#ff1e1e" }}>
              FIT
            </span>
            <span className="titletext" style={{ color: "#707070" }}>
              FLEX
            </span>
          </div>
          <h1 className="welcome" style={{ lineHeight: "40px" }}>
            Selamat Datang di Funzone!
          </h1>

          <p className="ttext" style={{ marginTop: "5px" }}>
            Silahkan masukkan nomor Anda.
          </p>
          <div className="inputdiv">
            <span className="myDropdown">
              <Dropdown overlay={menu_flag}>
                <img src={indonesia} className="flagstyle" />
              </Dropdown>
            </span>
            <Input
              className="inputfeild"
              value={number}
              maxLength={numberLength}
              onKeyDown={_handleKeyDown}
              bordered={false}
              // onChange={(e) => setNumber(e.target.value)}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                let numberLength = 13;
                // if value is not blank, then test the regex
                if (
                  e.target.value === "" ||
                  (re.test(e.target.value) &&
                    e.target.value.length <= numberLength)
                ) {
                  setNumber(e.target.value);
                }
              }}
              placeholder={"8XXXXXXXXXXXX"}
              prefix={
                <span
                  style={{
                    fontSize: 14,
                  }}
                >
                  {currentCountry.countryCode}
                </span>
              }
            />
          </div>
          <p
            className="ttext"
            style={{
              color: "red",
              marginTop: 20,
              marginBottom: 0,
              display: error != "" ? "flex" : "none",
            }}
          >
            {error}
          </p>
          {loading ? (
            <div className="loading-div">
              <Spin indicator={antIcon} tip="Loading..." />
            </div>
          ) : (
            <Button
              className="btn-login"
              onClick={() => {
                LoginFunction();
              }}
              id="sign-in-button"
            >
              Melanjutkan
            </Button>
          )}
        </div>

        {/* <div className="social-icons">
          <a href="https://www.facebook.com/Fitflex-108023608383081/">
            <img src={Facebook} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.instagram.com/fitflex_app/">
            <img src={Instagram} style={{ marginRight: 50 }} />
          </a>
          <a href="https://www.youtube.com/channel/UCajFgus4QBtdyA0MCTDSUZg">
            <img src={Youtube} className="icon-spacing" />
          </a>
        </div> */}
        <div id="recaptcha-container"></div>
        {/* <div
          style={{
            marginTop: "50px",
          }}
          className="center-images-on-resize"
        >
          <a href="https://play.google.com/store/apps" target="_blank">
            <img
              src={android}
              style={{
                width: 180,
                // maxWidth: "100%",
                height: "auto",
                marginRight: "4px",
              }}
              alt="playstore"
            />
          </a>
          <a href="https://www.apple.com/app-store/" target="_blank">
            <img
              src={apple}
              style={{
                width: 180,
                // maxWidth: "100%",
                height: "auto",
                border: "1px solid grey",
                borderRadius: "4px",
              }}
              alt="appstore"
            />
          </a>
        </div> */}
      </div>
    </div>
  );
};

const styles = {
  flag_dropdown: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: -10,
  },
  flag_text: {
    marginTop: 10,
    marginLeft: 10,
  },
};

export default DigiPacIndoLogin;
