import React, { useEffect, useState } from "react";
import { Link, Navigate, useSearchParams } from "react-router-dom";
import {
  isAuthenticated,
  authenticate,
  getTokenUserSegmentation,
  logUserV2,
  indoSignInSubscription,
} from "../api/requests";
import "../styles/login.css";
import "../styles/homescreen.css";
import "../styles/setplan.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Result } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
    spin
  />
);

const IndoLogin = () => {
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState();
  const [params] = useSearchParams();
  // const msisdn = "85282293570";

  const jwtToken = params.get("jwt_token");

  let loggingBody = {
    page: "indonesia_redirect_page",
    query: window.location.href,
  };

  let indoSubPayload = {
    package_id: "50",
    country_id: "100",
    token: jwtToken,
  };

  useEffect(() => {
    const handleIndoLogin = () => {
      localStorage.clear();
      if (isAuthenticated() === false) {
        indoSignInSubscription(indoSubPayload).then((res) => {
          if (res.status === 200) {
            logUserV2(loggingBody).then((response) => {
              if (response.status === "200") {
                localStorage.clear();
                authenticate(
                  res.data.userData.token,
                  res.data.msisdn,
                  "IND",
                  () => {
                    setRedirect(true);
                  }
                );
              } else {
                setError(true);
              }
            });
          } else {
            setError(true);
          }
        });
      } else {
        return <Navigate to={"/home"} />;
      }
    };
    handleIndoLogin();
  }, []);

  if (redirect) {
    return <Navigate to={"/LpAboutYou"} />;
  }

  if (error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Link to="/">Back Home</Link>}
      />
    );
  }

  return (
    <div class="loader">
      <Spin className="spinner" indicator={antIcon} tip="Redirecting..." />
    </div>
  );
};

export default IndoLogin;
